<template>
  <div>
    <ValidationProvider
      :rules="required ? 'required' : ''"
      name="เกม"
    >
      <b-form-group
        :label="hideLabel ? null : `หวย ${required ? '*' : ''}`"
        :label-cols-lg="hideLabel ? null : '3'"
        :label-cols-sm="hideLabel ? null : '12'"
      >
        <multiselect
            v-model="selectedGame"
            :options="filteredList"
            :placeholder="hasSelectAll ? 'เลือกทุกเกม' : 'เลือกเกม'"
            label="name"
            track-by="id"
        />
      </b-form-group>
    </ValidationProvider>
  </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
  name: 'LottoGameSelect',
  props: {
    value: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    hideLabel: {
      type: Boolean,
      default: false,
    },
    hasSelectAll: {
      type: Boolean,
      default: false,
    },
    lottoType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      selectedGame: null,
    }
  },
  computed: {
    ...mapGetters(['sortedLottoGameList']),
    filteredList() {
      return this.sortedLottoGameList.filter((game) => game.gameType === this.lottoType || !this.lottoType)
    }
  },
  watch: {
    lottoType(val) {
      if(val) {
        this.selectedGame = null
      }
    },
    selectedGame(val) {
      this.$emit('input', val?.id || null)
    }
  },
  async created() {
    await this.fetchLottoGameByGameType({
      limit: 1000,
    })
    if (this.value) {
      this.selectedGame = this.sortedLottoGameList.find((game) => game.id === this.value) || null
    }
  },
  methods: {
    ...mapActions(['fetchLottoGameByGameType']),
  }
}
</script>
